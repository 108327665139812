@import "app/styles/colors";

$input-field-font-size: 16px;
$input-field-v-padding: 10px;
$input-field-h-padding: 14px;
$input-field-border-radius: 3px;
$input-field-font-color: "#000";
$input-field-border-color: $gray-lightest;
$input-field-border-color-active: $teal;
$input-placeholder-color: $gray-light;
$input-disabled-bg-color: #f3f7f9;
